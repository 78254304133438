import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes } from "constants/index";

const ProductsComponent = () => {
  return (
    <>
      <div
        className="container product-wrapper"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="text-blue h2 text-center">Our Products</div>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-4 mt-3 mt-sm-5">
            <div className="card">
              <div className="text-blue">
                <div className="h4">Sickness Letter</div>
                <p className="">
                  <i className="fa fa-cash" />
                  &nbsp;Price starts at £15.00
                </p>
                <ul className="list-group list-style-type-none mt-2">
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Work Sickness
                    Certificate
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; University or College
                    Sickness Certificate
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; No appointment
                    required
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Upload video evidence
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Confirm validity with
                    QR Code
                  </li>
                  <li className="py-2 fw-600">
                    <i className="fa fa-check"></i>&nbsp; Get Certificate within
                    **3 hours** [7am – 9pm]
                  </li>
                </ul>
                <div className="row col-12 mt-3">
                  <div className="col-6">
                    <Link
                      to={Routes.REQUEST_SICKNOTE}
                      className="btn btn-blue-rounded col-12 text-violet font-small"
                    >
                      Buy Now
                    </Link>
                  </div>
                  <div className="col-6 d-none">
                    <Link
                      to={Routes.REQUEST_SICKNOTE}
                      className="btn btn-violet-outline-r col-12 text-violet font-small"
                    >
                      Learn more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <div className="text-blue">
                <div className="h4">Covid-19 Recovery Certificate</div>
                <p className="">
                  <i className="fa fa-cash" />
                  &nbsp;Price starts at £15.00
                </p>
                <ul className="list-group list-style-type-none mt-2">
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Simple questionnaire.
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; No appointment
                    required.
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Upload Test Result
                    Evidence in 3 minutes
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Confirm validity with
                    QR Code
                  </li>
                  <li className="py-2 fw-600">
                    <i className="fa fa-check"></i>&nbsp; Get Certificate within
                    **3 hours** [7am – 9pm]
                  </li>
                </ul>
                <div className="row col-12 mt-3">
                  <div className="col-7">
                    <Link
                      to={Routes.REQUEST_COVID_RECOVERY}
                      className="btn btn-blue-rounded col-12 text-violet font-small"
                    >
                      Buy Now
                    </Link>
                  </div>
                  <div className="col-6 d-none">
                    <Link
                      to={Routes.REQUEST_SICKNOTE}
                      className="btn btn-violet-outline-r col-12 text-violet font-small"
                    >
                      Learn more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <div className="text-blue">
                <div className="h4">Fitnote</div>
                <p className="">
                  <i className="fa fa-cash" />
                  &nbsp;Price starts at £15.00
                </p>
                <ul className="list-group list-style-type-none mt-2">
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Work Fitnote
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Simple Questionnaire
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; No appointment
                    required
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Upload video evidence
                  </li>
                  <li className="py-2">
                    <i className="fa fa-check"></i>&nbsp; Confirm validity with
                    QR Code
                  </li>
                  <li className="py-2 fw-600">
                    <i className="fa fa-check"></i>&nbsp; Get Certificate within
                    **3 hours** [7am – 9pm]
                  </li>
                </ul>
                <div className="row col-12 mt-3">
                  <div className="col-7">
                    <Link
                      to={Routes.REQUEST_SICKNOTE}
                      className="btn btn-blue-outline-r col-12 text-violet font-small disabled"
                    >
                      Coming Soon
                    </Link>
                  </div>
                  <div className="col-6 d-none">
                    <Link
                      to={Routes.REQUEST_SICKNOTE}
                      className="btn btn-violet-outline-r col-12 text-violet font-small"
                    >
                      Learn more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsComponent;
