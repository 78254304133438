import React from "react";
import { Link } from "react-router-dom";

import { StaticHeaderComponent } from "components";
import { Routes } from "constants/index";

const NotFoundComponent = () => {
  return (
    <>
      <StaticHeaderComponent />
      <div className="dashboard-content pt-5 mt-5 text-center">
        <h2 className="my-5 pt-5">Oops! This page is not available.</h2>
        <p>Use the following links to securely go back</p>
        <Link to={Routes.DEFAULT} className="btn btn-blue btn-sm px-3 my-2">
          {" "}
          GO HOME
        </Link>{" "}
      </div>
    </>
  );
};

export default NotFoundComponent;
