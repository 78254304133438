import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes } from "constants/index";
import { logo, gmcLogo } from "assets/images";

const FooterComponent = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-3 text-sm-left">
              <h2 className="row-header">
                <img src={logo} alt="logo" className="logo" />
                &nbsp;Ondoc
              </h2>
              <h4 className="row-subheader mt-4">
                <div className="my-2">On-demand</div>
                <div className="my-2">Doctors</div>
                <div className="my-2">Available</div>
              </h4>
            </div>
            <div className="col-12 col-sm-3 mt-4 my-sm-0 text-sm-left">
              <div className="h4 fw-400 my-2">Medical Letters</div>
              <ul className="row-list list-style-type-none">
                <li className="my-2">
                  <Link to={Routes.REQUEST_SICKNOTE}>Sickness Letters</Link>
                </li>
                <li className="my-2">
                  <Link to={Routes.REQUEST_SICKNOTE}>Fitnote</Link>
                </li>
                <li className="my-2">
                  <Link to={Routes.REQUEST_SICKNOTE}>
                    Recovery Certificates
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-3 mt-4 my-sm-0 text-sm-left">
              <div className="h4 fw-400 my-2">Fast Links</div>
              <ul className="row-list list-style-type-none">
                <li className="my-2">
                  <a href="#faq" className="text-violet">
                    FAQ
                  </a>
                </li>
                <li className="my-2">
                  <Link to={Routes.VERIFY_CERTIFICATE} className="text-violet">
                    Verify Certificate
                  </Link>
                </li>
                <li className="my-2">
                  <Link to={Routes.REQUEST_SICKNOTE} className="text-violet">
                    Request Medical Certificate
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-3 mt-4 my-sm-0 text-sm-left">
              <div className="">
                <div className="d-flex flex-row">
                  <img src={gmcLogo} alt="ondoc" className="gmc-logo" />
                </div>
              </div>
              <div className="mt-5">
                <div className="h6 fw-400">Secure Payment Methods</div>
                <div className="d-flex flex-row">
                  <i className="fa fa-cc-stripe fa-3x mx-2" />
                  <i className="fa fa-cc-visa fa-3x mx-2" />
                  <i className="fa fa-cc-mastercard fa-3x mx-2" />
                  <i className="fa fa-cc-amex fa-3x mx-2" />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 my-sm-0">
            <div className="col-12 col-sm-4">
              <div className="fw-300 fs-10">
                <Link to={Routes.PRIVACY_POLICY} className="text-white">
                  Privacy Policy
                </Link>{" "}
                &nbsp; | &nbsp;
                <Link to={Routes.TNC} className="text-white">
                  Terms and Conditions
                </Link>
                <Link to={Routes.TOU} className="text-white d-none">
                  Terms of Use
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
