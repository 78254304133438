import React from "react";
import { Helmet } from "react-helmet";

import { StaticHeaderComponent } from "components";
import { logo } from "assets/images";

const RequestSicknoteComponent = () => {
  return (
    <>
      <Helmet>
        <title>Ondoc - Request Covid Recovery</title>
        <meta
          name="description"
          content="Get a sick leave certificate on your own terms with our convenient online application. No need to take time off work or wait in line – just fill out the form and get your certificate in a snap."
        />
        <meta
          name="keywords"
          content="sick leave certificate form,sick leave certificate template,sick leave certificate,how to get sick leave certificate,sick leave certificate India"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={logo} />
        <link rel="canonical" href="https://blog.medoc.life" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Medical Certificate Online In Minutes By Certified Indian Doctors"
        />
        <meta
          property="og:description"
          content="Get a sick leave certificate on your own terms with our convenient online application. No need to take time off work or wait in line – just fill out the form and get your certificate in a snap."
        />
        <meta property="og:url" content="https://blog.medoc.life" />
        <meta property="og:site_name" content="Medoc.life" />
        <meta property="og:image" content={logo} />
        <meta property="og:image:width" content="590" />
        <meta property="og:image:height" content="615" />
        <meta
          property="article:modified_time"
          content="2022-12-23T04:31:02+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Medical Certificate Online In Minutes By Certified Indian Doctors"
        />
        <meta
          name="twitter:description"
          content="Get a sick leave certificate on your own terms with our convenient online application. No need to take time off work or wait in line – just fill out the form and get your certificate in a snap."
        />
        <meta name="twitter:image" content={logo} />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="2 mins." />
      </Helmet>
      <div>
        <StaticHeaderComponent />
        <div className="pt-4 mt-5">
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <iframe
                  src="https://form.jotform.com/230397315601350"
                  style="width: 100%; height: 1000px"
                />
              `,
            }}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

export default RequestSicknoteComponent;
