export const Faqs = [
  {
    question: "How long does it take to receive my medical certificate?",
    answer:
      "The time frame for receiving a medical certificate varies depending on the type of certificate, but most certificates are delivered within 24 hours after the form submission. We also offer expedited service for an additional fee for those who need their certificates more urgently. Your certificate will be sent via email.",
  },
  {
    question:
      "Is it safe and secure to use an online medical certificate platform?",
    answer:
      "Yes, our online medical certificate platform use encrypted technology and adhere to strict data protection regulations to ensure the safety and security of users’ personal and medical information. The platform also employs licensed healthcare professionals who are qualified and trained to provide accurate medical certificates.",
  },
  {
    question: "Will my workplace accept this certificate?",
    answer:
      "All the medical certificates provided by our platform adhere to the guidelines set by the General Medical Council (GMC) and are recognized as valid documents. These guidelines require that adequate evidence be provided to prove that the individual was indeed entitled to the sick note. In the event that additional evidence is needed, the relevant institution may request it. Our website provides a <a href='https://ondoc.life/verify-certificate' class='text-blue'>Verify Certificate</a> section where institutions can confirm the authenticity of the certificates.",
  },
  {
    question: "When are you available for service?",
    answer:
      "You can submit a request for a medical certificate at any time of day, seven days a week, and we will respond promptly, typically within 24 hours. It is important to note that response times may vary during overnight hours and on national holidays.",
  },
  {
    question: "Which areas do you operate?",
    answer:
      "Our healthcare professionals are located and serve people across England.",
  },
  {
    question: "What if I wish to alter the information on the medical letter?",
    answer:
      "If you wish to make any alterations to your medical certificate, you can reach out to us at <span class='fw-500 text-blue'>contact@ondoc.life</span> However, please keep in mind that the changes that can be made are limited to your personal information. Your assigned healthcare professional will only be able to update these specific details.",
  },
  {
    question: "Do I get refund in any case?",
    answer:
      "There may be times when your assigned healthcare professional determines that issuing you a medical certificate is not in your best health interests. This decision will always be made with your well-being as the top priority. If this occurs, you will receive a complete refund for your medical certificate.",
  },
];
