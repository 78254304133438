export const Testimonials = [
  {
    description:
      "Very swift, empathetic doctor. I completely satisfied with the experience. Thank you to the team for their services. ",
    name: "Sebastian",
  },
  {
    description: "The service is very good 🙌",
    name: "Lisa",
  },
  {
    description: "Extra ordinary. I received my certificate within 15 minutes.",
    name: "Faiz",
  },
  {
    description: "Superb experience 🫶",
    name: "Muhammad",
  },
  {
    description: "Fast and convienient services 🚀",
    name: "George",
  },
  {
    description: "Best service. Fully satisfied 👌👌",
    name: "Jack",
  },
  {
    description:
      "It was a speedy process and got the certificate from doctor buddy in 10 min! 👍",
    name: "Sajeev",
  },
  {
    description: "Received sick note in 30 minutes. Very Fast ✨",
    name: "Thao",
  },
  {
    description: "Great experience. Will definitely recommend Ondoc",
    name: "Sabrina",
  },
  {
    description: "Thanks for timely help. Affordable cost. ❤️",
    name: "Olivia",
  },
  {
    description: "Very nice. Super fast service!",
    name: "Ava",
  },
  {
    description: "Thank you so much for this help 😊",
    name: "Nicholas",
  },
];
