import React from "react";
import "./style.css";

const SectionOneComponent = ({ smallDevice }) => {
  return (
    <div className="section-two-wrapper pb-3 mt-5">
      <h2 className="text-center h6 text-secondary pt-4">Recognised by</h2>
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-center">
        <img
          src="https://storage.googleapis.com/ondoc-life-assets/images/GMC%20Logo.png"
          alt="play_image"
          className="col-6 col-md-2 mx-md-4 my-3"
        />
        <img
          src="https://storage.googleapis.com/ondoc-life-assets/images/trustpilot.png"
          alt="play_image"
          className="col-4 col-md-1 mx-md-4 my-3"
        />
      </div>
    </div>
  );
};

export default SectionOneComponent;
