import React from "react";

import { StaticHeaderComponent, StaticFooterComponent } from "components";

const PrivacyPolicyComponent = () => {
  return (
    <>
      <div>
        <StaticHeaderComponent />
        <div className="container pt-5 px-sm-5 mt-5">
          <h5>Terms and Conditions</h5>
          <p>
            Terms and conditions for provision of services between Ondoc Limited
            and a person seeking medical services
          </p>
          <p>1. These terms</p>
          <p>
            1.1 What these terms cover These Terms, along with our Privacy
            Policy, govern your use of the Ondoc site, located at www.ondoc.life
            and available through mobile app stores, and the services provided
            through it. By using the Ondoc site, you acknowledge and agree to
            comply with these Terms and our Terms of Use.
          </p>
          <p>
            By accessing these terms, you agree to the following terms in
            particular:
          </p>
          <p>Your personal information</p>
          <p>
            By providing personal information, including sensitive information,
            to us through the Ondoc site, you consent to our collection, use,
            and disclosure of such information in accordance with these Terms
            and our Privacy Policy. This includes sharing your information with
            all physicians providing services through the Ondoc site, and, with
            your consent, with your primary care physician.
          </p>
          <p>The terms of service</p>
          <p>
            If you wish to receive medical services through the Ondoc site or
            Ondoc Medical Letter Platform, you will be required to accept the
            terms and conditions of the physician providing the services, known
            as the Doctor Terms. Ondoc is not a provider of healthcare services
            and does not participate in any consultations or diagnoses. The
            Doctor Terms will be made available to you when requesting services
            from a specific physician through the Ondoc site or Ondoc Medical
            Letter Platform.
          </p>
          <p>Your cancellation rights</p>
          <p>
            By using the Ondoc site and requesting services from physicians, you
            agree to waive any rights to cancel such services, except as
            outlined in these Terms and the Doctor Terms.
          </p>
          <p>The limits of our liability</p>
          <p>
            Ondoc shall not be held liable, either directly or indirectly, for
            any actions or omissions of physicians in response to requests for
            medical letters.
          </p>
          <p>
            The Ondoc site is protected by copyright and owned by us, and your
            use of it is governed by the Terms of Use. You are granted a licence
            to use the Ondoc site in accordance with these terms.
          </p>
          <p>
            1.2 The services provided on this website are not intended for use
            in case of emergency medical situations. In the event of an
            emergency, it is imperative that you seek immediate assistance by
            calling emergency services (999) or visiting the nearest hospital.
            Failure to do so may result in serious harm or injury. Use of the
            services provided on this website is at the user's own risk.
          </p>
          <p>1.3 Why you should read them</p>
          <p>
            1.3.1 It is of the utmost importance that you take the time to
            carefully read and understand these terms and conditions before
            accessing the Ondoc site or purchasing a Medical Letter. These terms
            provide crucial information regarding the identity of the website's
            operators, the procedures for accessing the site and the services it
            offers, as well as the conditions for altering or terminating the
            contract, and what steps to take in case of any issues. Should you
            have any concerns or believe there to be any errors in these terms,
            please do not hesitate to contact us for further clarification.
          </p>
          <p>
            1.3.2 It is highly recommended that you review these terms and
            conditions on a regular basis, as they can be accessed at any time
            via the website Ondoc.life. These terms may be subject to change, in
            compliance with relevant laws and regulations, without the need for
            your explicit consent. Should changes be made, we will provide
            reasonable notice and inform you of the nature of the changes
            wherever possible. We will only make other changes to the terms if
            we notify you in advance. Any changes made to the terms will be
            displayed on the website, and by continuing to use the Ondoc
            website, you are indicating your acceptance of such changes.
          </p>
          <p>2. Information about us and how to contact us</p>
          <p>2.1. Who we are</p>
          <p>
            We are Ondoc Limited, a company incorporated and registered under
            the laws of England, with company registration number 14579544 and
            registered office located at 1 Craftsman Square, Temple Farm
            Industrial Estate, Southend-On-Sea, England, SS2 5RH. As a limited
            company, we are subject to regulation by Companies House.
          </p>
          <p>2.2. Who you are</p>
          <p>
            "You" refers to the individual accessing the Ondoc site, purchasing
            a Medical Letter, or utilising a Health Test in accordance with
            these terms and conditions. As the "Patient," you will be the
            recipient of services provided through the Ondoc site, or you will
            be accessing services on behalf of another individual who will be
            considered the Patient. By accessing the Ondoc site, you confirm
            that you have the legal authority and capacity to enter into these
            terms and conditions. If you do not agree with all the provisions
            outlined in these terms, we ask that you refrain from accessing our
            services.
          </p>
          <p>2.3 Obtained permission</p>
          <p>
            It is assumed that you have obtained the necessary permission from
            the owners of any devices that you control but do not own, to
            download or stream a copy of the Ondoc site onto said devices.
            Please be aware that usage of internet access on these devices may
            result in charges from your and their service providers. By
            accessing the Ondoc site on any device, whether or not it is owned
            by you, you accept responsibility for compliance with these terms
            and conditions and for any use of the Ondoc site and receipt of
            services accessed through it in relation to said device.
          </p>
          <p>2.4 Eligible to register</p>
          <p>
            In order to be eligible to request an Ondoc medical letter, you must
            be 18 years of age or older. Parents or legal guardians may take
            responsibility for children under the age of 18 and complete forms
            on their behalf.
          </p>
          <p>2.5. How to contact us</p>
          <p>
            You can contact us by either calling us at [Insert telephone number]
            or by sending an email to contact@ondoc.life.
          </p>
          <p>2.6. How we may contact you</p>
          <p>
            In the event that we need to contact you, we will do so by telephone
            or by writing to the email address or postal address that you
            provided to us when requesting our services.
          </p>
          <p>2.7. Your e-mail address</p>
          <p>
            It is your responsibility to ensure that we have your current and
            valid email address on file. Should the email address provided by
            you be invalid or for any reason incapable of receiving the
            aforementioned notice, the email containing such notice shall still
            be considered as effective notice of the changes described therein.
          </p>
          <p>3. Services</p>
          <p>3.1 Services we will provide</p>
          <p>
            Ondoc is an online platform that allows you to access medical
            services provided by a General Practitioner through the request of a
            Medical Letter and/or a Covid-19 recovery certificate. The detailed
            terms of these services are in accordance with sections 6, 7 and 8
            of these Terms.
          </p>
          <p>3.2 Services you can access via the Ondoc site</p>
          <p>
            Medical services are provided by the Doctors. Doctors will provide
            services to you on terms which you can accept when you request a
            Medical Letter and/or a Covid-19 recovery certificate. Doctors are
            not employees of Ondoc.
          </p>
          <p>
            The Ondoc platform serves as a medium for accessing medical services
            provided by qualified and registered General Practitioners through
            the request of Medical Letters and Covid-19 recovery certificates.
            However, Ondoc does not provide medical services directly and cannot
            be held liable for the actions or omissions of the doctors that
            provide services through our platform. It is important to note that
            Ondoc does not endorse or guarantee the accuracy or quality of the
            advice or services provided by the doctors. In case of an emergency,
            we advise you to contact emergency services (999) immediately. Ondoc
            will not be responsible for any damages caused by delays or failures
            of the Ondoc platform or a delay in the performance of our services.
            In the event that our performance is affected by unforeseen
            circumstances, we will take necessary steps to minimise the impact
            of such delays and will communicate with you as soon as possible.
          </p>
          <p>3.4 Covid-19 Recovery Certificates</p>
          <p>
            Recovery certificates are provided via the Ondoc Medical Letter
            Platform, which are assessed and issued by qualified and registered
            doctors, not by Ondoc or its employees. The issuing of a recovery
            certificate is at the discretion of our medical team after
            evaluating the evidence provided. Our recovery certificates are
            valid for up to 180 days from the date of a patient's positive PCR
            or Lateral Flow Covid-19 test result. It is the responsibility of
            the patient or customer to check the latest guidelines and
            suitability of our recovery certificates with their airline, border
            control or destination, to ensure they meet their individual
            requirements. Ondoc will not be held liable if the certificate does
            not meet the specific requirements. All of our recovery certificates
            have QR codes to confirm validity and enable checks. It is important
            to note that the QR code may not sync with EU or other international
            digital Covid recovery passes. Once a certificate has been sent and
            provided to a customer or patient, Ondoc will not be able to provide
            a refund under any circumstances. In case of cancellation, prior to
            issuing a certificate, a full refund will be provided. If we are
            unable to provide a certificate due to non-eligibility, a full
            refund will be provided.
          </p>
          <p>3.5 Medical Letters and Certificates</p>
          <p>
            Medical Letters and Certificates are provided via the Ondoc Medical
            Letter Platform and are issued by individual Ondoc doctors. The
            Ondoc doctors are solely responsible for the patients, services, and
            letters they provide. The issuance of Medical Letters and
            Certificates is at the sole discretion of Ondoc doctors after
            evaluating the online medical questionnaire, and clinical evidence
            (such as video or images) provided. Our doctors may contact you to
            discuss your symptoms or evidence before providing a medical letter.
          </p>
          <p>
            In case of insufficient clinical information, our doctors may cancel
            your letter request. As part of our patient verification procedure,
            patients are required to provide identification documents such as a
            passport, driver's licence, or ID.
          </p>
          <p>
            If an employer, airline, or third party does not accept or rejects
            an Ondoc letter or certificate for any reason, neither Ondoc nor our
            doctors will be held responsible for any costs incurred.
          </p>
          <p>
            If our doctors have any clinical concerns regarding patient safety,
            they may contact a patient's regular Doctor or other regulatory
            bodies in line with GMC guidelines and UK doctors' responsibilities.
          </p>
          <p>
            Providing inaccurate, misleading, or incomplete information to
            obtain a medical letter may be considered as fraud. In case of
            insurance purposes, it is the patient's responsibility to ensure
            that the medical letter provided by us meets the requirements of
            their insurer.
          </p>
          <p>
            After a medical letter has been issued and sent to a patient, Ondoc
            will not be able to provide a refund. If the required evidence is
            not provided, Ondoc doctors will cancel your letter request and
            provide a full refund. Ondoc will not be held liable for the doctors
            using their professional medical discretion to not provide a medical
            letter or certificate. If you believe you or the relevant patient
            requires urgent medical attention, we advise you to contact
            emergency services (999) or speak to a Doctor or healthcare
            professional immediately.
          </p>
          <p>4. Accounts</p>
          <p>4.1 Account Creation</p>
          <p>
            In order to use the Ondoc site, you must register for an account
            (your Ondoc Account) and provide the following prompted by the
            account registration form:
          </p>
          <p>
            Email, mobile telephone number, name, date of birth, gender,
            address, payment details.
          </p>
          <p>
            Certain details requested that are optional include: Pre-existing
            health problems, current medication and any allergies.
          </p>
          <p>
            You may also provide the contact details of the regular Doctor you
            are registered with (together, the “Patient Information”).
          </p>
          <p>4.2 Multiple patients</p>
          <p>
            Your Ondoc Account may be used to register multiple Patients to
            receive services, for example all Family members. Where that is the
            case, Patient Information will need to be provided for each Patient.
            Each Patient will be deemed to have accepted these Terms.
          </p>
          <p>4.3 Your confirmations</p>
          <p>
            By accessing and using the Ondoc platform and its services, you
            confirm that:
          </p>
          <p>
            (a) all Patient Information you submit is truthful and accurate; (b)
            you have obtained the consent of all relevant individuals in respect
            of the Patient Information you have provided; (c) you will maintain
            the accuracy of such Patient Information and inform us if any such
            information changes.
          </p>
          <p>4.4 Service access</p>
          <p>
            By accessing and using the Ondoc platform and its services, you
            agree that if you do not provide the required Patient Information in
            a reasonable time frame or if the information provided is incomplete
            or incorrect, you may not be able to access services from our
            doctors.
          </p>
          <p>4.5 Account deletion</p>
          <p>
            You have the option to delete your Ondoc account at any time, for
            any reason, by following the instructions on the Ondoc site. Ondoc
            reserves the right to suspend or terminate your account in
            accordance with the terms outlined in sections 12 and 13 of these
            terms and conditions.
          </p>
          <p>4.6 Account Responsibilities</p>
          <p>
            You are responsible for maintaining the confidentiality of your
            Ondoc account login and password information, and you are fully
            responsible for all activities that occur under your Ondoc account.
            You agree to immediately notify Ondoc of any unauthorised use or
            suspected unauthorised use of your account or any other breach of
            security by emailing contact@ondoc.life. Subject to the terms
            outlined in section 11 of these terms and conditions, Ondoc cannot
            be held liable for any loss or damage arising from your failure to
            comply with these requirements.
          </p>
          <p>5. Access to the Ondoc site</p>
          <p>5.1 Licence</p>
          <p>
            Subject to these terms and conditions, Ondoc grants you a
            non-transferable, non-exclusive, revocable, limited licence to use
            and access the Ondoc site solely for your own personal,
            non-commercial use for the purpose of requesting Medical Letters and
            Covid-19 Recovery Certificates.
          </p>
          <p>5.2 Specific Restrictions</p>
          <p>
            If you are not the person benefiting from the Doctor's services, you
            may only request Medical Letters and Covid-19 Recovery Certificates
            for the patient, if:
          </p>
          <p>
            a. the patient is a family member, or b. the patient is under the
            age of 18 years old or you are otherwise the parent or guardian of
            that patient, and the patient is registered under your Ondoc
            account.
          </p>
          <p>5.3 Other Restrictions</p>
          <p>
            The rights granted to you in these terms and conditions are subject
            to the following restrictions:
          </p>
          <p>
            (a) you will not licence, sell, rent, lease, transfer, assign,
            distribute, host, or otherwise commercially exploit the Ondoc site
            or Ondoc Medical Letter Platform, or any content displayed on the
            Ondoc site or Ondoc Medical Letter Platform, in whole or in part.
            (b) you will not modify, make derivative works of, disassemble,
            reverse compile, or reverse engineer any part of the Ondoc site or
            Ondoc Medical Letter Platform. (c) you will not access the Ondoc
            site or Ondoc Medical Letter Platform in order to build a similar or
            competitive website, product, or service. (d) except as expressly
            stated herein, no part of the Ondoc site or Ondoc Medical Letter
            Platform may be copied, reproduced, distributed, republished,
            downloaded, displayed, posted or transmitted in any form or by any
            means.
          </p>
          <p>
            Any future release, update, or other addition to functionality or
            content of the Ondoc site or Ondoc Medical Letter Platform will be
            subject to these terms and conditions.
          </p>
          <p>5.4 copyright and other proprietary notices</p>
          <p>
            You must not remove or alter any proprietary notices on the Ondoc
            site or Ondoc Medical Letter Platform (or on any content displayed
            on the Ondoc site or Ondoc Medical Letter Platform). All rights not
            expressly granted in these terms and conditions are reserved and
            retained by Ondoc or its licensors, suppliers, publishers,
            rightsholders, or other content providers. Any use of the Ondoc site
            or Ondoc Medical Letter Platform not expressly permitted by these
            terms and conditions is a breach of these terms and conditions and
            may violate copyright, trademark, and other laws.
          </p>
          <p>5.5 Modification</p>
          <p>
            You also agree that Ondoc has the right to make changes to these
            terms and conditions from time to time and that your continued use
            of the Ondoc site or Ondoc Medical Letter Platform following any
            such changes shall be deemed to be your acceptance of such changes.
            It is your responsibility to regularly check these terms and
            conditions for any modifications or updates. If you do not agree to
            any changes made to these terms and conditions, you should
            discontinue your use of the Ondoc site or Ondoc Medical Letter
            Platform immediately.
          </p>
          <p>5.6 No Support or Maintenance</p>
          <p>
            You also acknowledge and agree that Ondoc may, from time to time,
            update the Ondoc site or Ondoc Medical Letter Platform in order to
            add new features, improve performance, or address security issues.
            However, Ondoc is under no obligation to provide any such updates,
            and it is your responsibility to ensure that your device and
            internet connection meet the necessary requirements to access and
            use the Ondoc site or Ondoc Medical Letter Platform.
          </p>
          <p>5.7 Ownership</p>
          <p>
            Content: You acknowledge and agree that Ondoc Limited owns all
            rights, title, and interest in and to the Ondoc site and Ondoc
            Medical Letter Platform, including all intellectual property rights
            such as copyrights, patents, trademarks, and trade secrets. Your
            access to the Ondoc site and Ondoc Medical Letter Platform is
            limited to the rights granted in these terms, and Ondoc and its
            suppliers reserve all rights not explicitly granted. No implied
            licences are granted under these terms.
          </p>
          <p>VAT 8.11</p>
          <p>
            Any fees or charges for our services are inclusive of VAT. If there
            is a change in the VAT rate, we will reflect that change in the fees
            and charges for our services, unless payment for the services have
            already been made in full before the change in VAT rate takes
            effect.
          </p>
          <p>Disputes and Complaints 8.12</p>
          <p>
            Ondoc will review the Dispute and make a determination on the amount
            due and payable, taking into account all relevant information and
            evidence provided. If Ondoc determines that the fees charged by the
            Ondoc Doctor were incorrect, Ondoc will adjust the fees accordingly
            and issue a refund or credit to you, as appropriate. If you have any
            complaints regarding the services provided by the Ondoc Doctor,
            please contact us at contact@ondoc.life and we will investigate and
            respond to your complaint in a timely manner. We encourage you to
            raise any concerns or disputes as soon as possible to allow us to
            resolve the issue quickly and efficiently.
          </p>
          <p>
            8.13 You expressly acknowledge and agree that any Dispute is between
            you and the Ondoc Doctor and to the fullest extent permitted by law
            and the terms of section 11, Ondoc accepts no liability in respect
            of the payment of fees to the Ondoc Doctor. However, Ondoc Doctors
            have agreed that Ondoc may investigate Disputes on their behalf and
            that Ondoc may arrange for third party payment processors to retain
            fees due to Doctors (and then return them to you) where, following
            investigation, we agree that you have legitimate grounds for the
            Dispute.
          </p>
          <p>
            8.14 Content: In addition to the Dispute process outlined above, you
            may also bring any complaints or disputes about our services to the
            attention of Ondoc by contacting us at contact@ondoc.life. We will
            make every effort to resolve any complaints or disputes in a timely
            and fair manner. If you are not satisfied with the resolution
            offered by Ondoc, you may seek alternative dispute resolution
            through an independent body such as the Centre for Effective Dispute
            Resolution (CEDR). CEDR provides independent adjudication on
            complaints against healthcare providers. You can contact CEDR via
            their website at https://www.cedr.com.
          </p>
          <p>Processing of Payments</p>
          <p>
            8.16 We utilise a trusted third-party payment processor to handle
            all transactions related to the Doctor's Fees (“Payment Services”).
            The processing of any payments made through our website will be
            subject to the terms of service and privacy policy of this
            third-party provider, which can be accessed during the payment
            process.
          </p>
          <p>
            8.17 We cannot be held responsible for any damages, direct or
            indirect, resulting from the use of the Payment Services.
          </p>
          <p>9. Personal data</p>
          <p>
            9.1 In order to use the Ondoc site, you may be required to disclose
            to us certain personal data, including Patient Information and
            Consultation Information (which may include sensitive personal data)
            relating to you and other data subjects. You consent to providing us
            with this personal information, including financial and medical
            information.
          </p>
          <p>
            9.2 You confirm that you consent to our use of your personal
            information as described above and that each Patient has agreed that
            you, as the holder of the Ondoc Account, may access and receive and
            disclose to us their personal information as described above. You
            acknowledge that while we seek your consent to use your personal
            information for the purposes of being open and transparent, we do
            not rely solely on this consent as the lawful basis for processing
            your personal information. Please see our privacy policy (link) for
            more information on this.
          </p>
          <p>
            9.3 You acknowledge that the medical letters(Sick notes), Covid-19
            certificates providers available on Ondoc site are not permitted to
            use any personal data obtained about you or any other Patient for
            any purpose except as set out in the terms you enter into with such
            providers and our privacy policy (link).
          </p>
          <p>
            9.4 Notwithstanding this, you expressly acknowledge and agree as
            follows:
          </p>
          <p>
            a. We may access and use your data for audit, performance monitoring
            and dealing with complaints and disputes, and for providing your
            data to other service providers on the Ondoc site where such
            providers require access to such information, for example because
            you request services from them;
          </p>
          <p>
            b. any data disclosed by you or another Patient during a
            Consultation or otherwise is disclosed entirely at your or the
            Patient’s own risk;
          </p>
          <p>
            c. any data that you disclose can be used in accordance with our
            privacy policy (link) and the terms you enter into with such
            providers, which may include the transfer of such data to certain
            third parties;
          </p>
          <p>
            d. to the fullest extent permitted by law and the terms of section
            10, we are not liable to you in respect of any damages, losses or
            expenses incurred by you and/or any other Patient as a result of any
            provider's use of your data.
          </p>
          <p>
            9.4 Services provided via the Ondoc site may make use of location
            data sent from the Devices. You can turn off this functionality at
            any time by turning off the location services settings for the Ondoc
            site on the Device. If you use these services, you consent to us and
            our affiliates’ and licensees’ transmission, collection, retention,
            maintenance, processing and use of your location data and queries to
            provide and improve location-based and road traffic-based products
            and services. You may withdraw this consent at any time by turning
            off the location services settings on the Devices.
          </p>
          <p>10. Disclaimers</p>
          <p>
            The Ondoc site is provided on an “as-is” and “as available” basis,
            and, to the extent permitted by applicable law, Ondoc (and our
            suppliers) expressly disclaim any and all warranties and conditions
            of any kind, whether express or implied, including but not limited
            to, implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement.
          </p>
          <p>
            We (and our suppliers) make no warranty that the Ondoc site will
            meet your requirements, will be available on an uninterrupted,
            timely, secure, or error-free basis, or will be accurate, reliable,
            free of viruses or other harmful code, complete, legal, or safe.
            Ondoc does not warrant that the functions contained in the site will
            be uninterrupted or error-free, that defects will be corrected, or
            that the site or the servers that make it available are free of
            viruses or other harmful components. Ondoc does not warrant or make
            any representation regarding the use or the results of the use of
            the materials in this site in terms of their correctness, accuracy,
            reliability, or otherwise. You assume the entire cost of all
            necessary servicing, repair, or correction.
          </p>
          <p>11. Limitation on liability</p>
          <p>
            11.1 In the case of personal injury or death resulting from our
            negligence, we will be liable to you without any limits.
          </p>
          <p>
            11.2 We will not seek to limit or exclude our liability in any cases
            where it is legally prohibited to do so.
          </p>
          <p>
            11.3 In cases other than those outlined in sections 11.1 and 11.2,
            our liability to you will be limited to 150% of the fees you paid to
            the Doctor for the services that caused the liability.
          </p>
          <p>
            11.4 If we fail to meet our obligations under these terms, we will
            be responsible for any foreseeable losses or damages you may incur
            as a result of our breach of contract or failure to exercise
            reasonable care and skill. However, we will not be liable for any
            unforeseeable losses or damages. Losses or damages are considered
            foreseeable if they were either obvious or if both parties were
            aware of the possibility at the time of the contract. This includes,
            but is not limited to, loss of profits and loss of data.
          </p>
          <p>
            11.5 Access to and use of the Ondoc site is at your own risk, and
            you will be solely responsible for any damage to your device or
            computer system, or loss of data that may result. Ondoc will not be
            held liable for any damages arising from the use of this site,
            including but not limited to direct, indirect, incidental, punitive,
            and consequential damages.
          </p>
          <p>12. Suspension of the services</p>
          <p>
            12.1 We reserve the right to temporarily suspend the services
            available to you via the Ondoc site under the following
            circumstances:
          </p>
          <p>
            to address technical issues or make necessary technical changes;
          </p>
          <p>
            to update the services in accordance with changes in relevant laws
            and regulatory requirements;
          </p>
          <p>
            if, in the reasonable professional opinion of a Doctor, it is not
            suitable for you to receive the service;
          </p>
          <p>
            if you engage in behaviour that we consider to be abusive, violent
            or threatening;
          </p>
          <p>
            if we are instructed by an emergency service provider not to provide
            the service, for as long as that instruction applies;
          </p>
          <p>
            if you do not cooperate with us or do not comply with these terms,
            for example by failing to make payments for services provided by the
            Doctor.
          </p>
          <p>
            12.2 We will make every effort to notify you in advance of any
            suspensions, unless the situation is urgent or an emergency. If the
            suspension lasts for more than 2 weeks, we reserve the right to
            terminate the services and your access to them via the Ondoc site.
            If possible, we will provide you with information about the actions
            we are taking, when they will take effect, and the reasons for them.
            We will also inform you of your right to challenge the actions taken
            through our complaints process.
          </p>
          <p>13. Term and termination</p>
          <p>
            13.1 These Terms will remain in effect while you use the Ondoc site,
            and you may terminate the contract between you and us at any time by
            disabling your Ondoc account, provided you are not currently
            receiving services from the doctors via the Ondoc site.
          </p>
          <p>
            13.2 We reserve the right to suspend or terminate your rights to use
            the Ondoc site, including your account, at any time for any reason
            at our sole discretion, including for any violations of these Terms.
          </p>
          <p>
            13.3 Upon termination of your rights under these Terms, your account
            and access to the Ondoc site will be terminated immediately. You
            understand that any termination of your account may result in the
            deletion of your User Content associated with your account from our
            live databases.
          </p>
          <p>
            13.4 Ondoc will not be liable to you for any termination of your
            rights under these Terms, including termination of your account or
            deletion of your User Content. Even after your rights under these
            Terms are terminated, the following sections will continue to remain
            in effect: 9, 10, and 11.
          </p>
          <p>14. General</p>
          <p>
            14.1 Entire Terms: These Terms represent the complete agreement
            between you and us regarding the use of the Ondoc site, and
            supersedes all prior or contemporaneous understandings, agreements,
            representations, and warranties, both written and oral, regarding
            the Ondoc site.
          </p>
          <p>
            14.2 Waiver: Any waiver of any right or remedy by Ondoc under these
            Terms will only be effective if it is in writing and signed by a
            duly authorised representative of Ondoc Limited. It will apply only
            to the specific circumstances for which it was given. Our failure to
            exercise or enforce any right or remedy under these Terms will not
            be deemed as a waiver of such right or remedy, and will not prevent
            us from exercising or enforcing such right or remedy at any time in
            the future. The exercise of any right or remedy will not preclude or
            restrict the further exercise of any other right or remedy.
          </p>
          <p>
            14.3 Headings and Interpretation: The section titles in these Terms
            are for convenience only and have no legal or contractual
            significance. The use of the word "including" in these Terms shall
            be interpreted as "including, but not limited to".
          </p>
          <p>
            14.4 Severability: If any provision of these Terms is deemed invalid
            or unenforceable for any reason, the remaining provisions will
            remain in full force and effect. The invalid or unenforceable
            provision will be modified to the extent necessary to make it valid
            and enforceable, consistent with the intent of these Terms and
            applicable laws.
          </p>
          <p>
            14.5 Relationship between you and us: You confirm that you are
            acting on your own behalf and not for the benefit of any other
            person. Your relationship to Ondoc is that of an independent
            contractor, and neither party is an agent or partner of the other.
          </p>
          <p>
            14.6 Transfer of these Terms: You may not assign, subcontract,
            delegate, or transfer your rights and obligations under these Terms
            without the prior written consent of Ondoc Limited. Any attempt to
            do so without consent will be considered null and void. Ondoc may
            freely assign these terms without your consent, but will provide you
            with advance notice. Any assignees will be bound by the terms and
            conditions outlined in these Terms.
          </p>
          <p>
            14.7 Governing Law and Jurisdiction: These Terms, as well as any
            disputes or claims related to their subject matter or formation
            (including non-contractual disputes or claims), will be governed by
            and interpreted in accordance with the laws of England. By agreeing
            to these Terms, you also agree to submit to the exclusive
            jurisdiction of the courts of England to resolve any disputes or
            claims arising from or in relation to the subject matter or
            formation of these Terms.
          </p>
          <p>
            14.8 Copyright/Trademark Information: The Ondoc site, including all
            content and materials, is protected by copyright © 2021 Ondoc
            Limited. All rights reserved. Any trademarks, logos, and service
            marks (“Marks”) displayed on the Ondoc site and website (Ondoc.life)
            are our property or the property of other third parties. Without our
            prior written consent or the consent of the third party that owns
            the Marks, you are not permitted to use these Marks.
          </p>
        </div>
        <StaticFooterComponent />
      </div>
    </>
  );
};

export default PrivacyPolicyComponent;
