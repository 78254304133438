import React from "react";
import "./style.css";

import { StaticHeaderComponent, StaticFooterComponent } from "components";

const AboutUsComponent = () => {
  return (
    <>
      <div>
        <StaticHeaderComponent />
        <div className="container pt-5 px-5 mt-5">
          <h2 className="text-center mt-4">About us</h2>
          <div className="mt-5">
            <div className="h4">GMC Doctors with tech enthusiasts</div>
            <p className="body">
              Ondoc.life is started with the collobaration of trusted healthcare
              professionals and enthusiastic tech individuals with a mission to
              improve UK healthcare system. We are passionate about improving
              healthcare for everyone and are constantly working to expand our
              services and improve the patient experience.
            </p>
            <div className="my-5">
              <img
                src="https://storage.googleapis.com/ondoc-life-assets/images/GMC%20Logo.png"
                alt="ondoc"
                className="gmc-logo"
              />
            </div>
            <p>
              We are committed to making healthcare more accessible and
              convenient for everyone. With our platform, you can connect with
              real Medical professionals from the comfort of your own home or
              office, using your smartphone, tablet, or computer.
            </p>
            <p>
              Our team of experienced Medical professionals is dedicated to
              providing high-quality, personalized solutions to all of our
              patients. We understand that finding time to see a doctor in
              person can be difficult, which is why we offer convenient online
              appointments that fit your schedule.
            </p>
            <p>
              In addition to Work sickness and university sickness certificates,
              we also offer Covid-19 recovery certificates for Fit to Fly
              purposes. No matter what your healthcare certification needs may
              be, we are here to help.
            </p>
            <p>
              Our platform is secure and easy to use, with state-of-the-art
              encryption to protect your personal information. We also accept
              most major insurance providers, making it easy for you to access
              the care you need.
            </p>
            <p>
              We are passionate about improving healthcare for everyone and are
              constantly working to expand our services and improve the patient
              experience. Thank you for choosing Ondoc.life, for your healthcare
              needs. We look forward to helping you on your journey to better
              health.
            </p>
          </div>
        </div>
        <StaticFooterComponent />
      </div>
    </>
  );
};

export default AboutUsComponent;
