import React from "react";
import { Link } from "react-router-dom";

import { logo } from "assets/images";
import { Routes } from "constants/index";
import "./style.css";

const StaticHeaderComponent = () => {
  return (
    <div className="static-header">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <Link to={Routes.DEFAULT} className="home-page-title fw-600">
              <img src={logo} alt="Ondoc logo" className="logo" /> Ondoc
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item px-2">
                <div className="nav-link fw-500">
                  <Link to={Routes.ABOUT_US} className="text-blue">
                    About us
                  </Link>
                </div>
              </li>
              <li className="nav-item px-2">
                <div className="nav-link fw-500">
                  <Link to={Routes.ABOUT_US} className="text-blue">
                    Blogs
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <Link
                    to={Routes.REQUEST_SICKNOTE}
                    className="btn btn-blue-outline-r py-2 px-4"
                  >
                    Request Certificate
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StaticHeaderComponent;
