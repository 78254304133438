import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes } from "constants/index";

const AboutUsComponent = () => {
  return (
    <>
      <div className="container about-us-wrapper">
        <div className="h2 text-center pb-4">Who are we?</div>
        <div
          className="container px-sm-5 text-center"
          data-aos="flip-right"
          data-aos-duration="2000"
        >
          Ondoc.life is started with the collobaration of trusted healthcare
          professionals and enthusiastic tech individuals with a mission to
          improve UK healthcare system. We are passionate about improving
          healthcare for everyone and are constantly working to expand our
          services and improve the patient experience.
        </div>
        <Link
          to={Routes.ABOUT_US}
          className="btn btn-white-rounded px-4 mt-5"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          Learn More
        </Link>
      </div>
    </>
  );
};

export default AboutUsComponent;
