export const Routes = {
  DEFAULT: "/",
  HOME: "/",
  TNC: "/tnc",
  PRIVACY_POLICY: "/pp",
  TERMS_OF_USE: "/tu",
  COOKIE_POLICY: "/cp",
  ABOUT_US: "/about-us",
  REQUEST_SICKNOTE: "/request-sicknote",
  REQUEST_COVID_RECOVERY: "/request-covid-recovery",
  REQUEST_SUCCESS: "/request-success",
  VERIFY_CERTIFICATE: "/verify-certificate",
};
