import React from "react";

import {
  StaticHeaderComponent,
  StaticFooterComponent,
  TestimonialsComponent,
} from "components";
import SectionOneComponent from "./section_one/component";
import SectionTwoComponent from "./section_two/component";
import ProductsComponent from "./products/component";
import AboutUsComponent from "./about_us/component";
import FaqComponent from "./faq/component";
import HowItWorksComponent from "./how_it_works/component";

const HomeComponent = () => {
  return (
    <>
      <div>
        <StaticHeaderComponent />
        <div className="pt-4 mt-5">
          <SectionOneComponent />
          <SectionTwoComponent />
          <ProductsComponent />
          <HowItWorksComponent />
          <TestimonialsComponent />
          <AboutUsComponent />
          <FaqComponent />
          <StaticFooterComponent />
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
