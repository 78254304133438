import React from "react";
import "./style.css";

import { Faqs } from "constants/index";

const FaqComponent = () => {
  return (
    <>
      <div className="text-blue h2 text-center px-sm-5 mx-sm-5">
        FAQs
        <p
          className="fs-18 pt-4 fw-300 px-3 px-sm-5 mx-sm-5"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          We understand that managing your health can be challenging, so we've
          answered all potential questions to make the process as smooth as
          possible.
        </p>
      </div>
      <div className="faq container mb-5">
        {Faqs.map((obj, objIdx) => (
          <div
            className="container"
            key={`${obj.key}_${objIdx}`}
            data-aos="zoom-out"
            data-aos-duration="1000"
          >
            <input
              type="checkbox"
              id={`question_${objIdx}`}
              name="q"
              className="questions"
            />
            <div>
              <label
                htmlFor={`question_${objIdx}`}
                className="d-flex flex-row align-items-center justify-content-between question fs-24 fw-500"
              >
                <div>{obj.question}</div>
                <div className="plus">+</div>
              </label>
            </div>
            <div
              className="answers fw-500 pt-2"
              dangerouslySetInnerHTML={{ __html: obj.answer }}
            ></div>
            {objIdx < Faqs.length - 1 && <hr />}
          </div>
        ))}
      </div>
    </>
  );
};

export default FaqComponent;
