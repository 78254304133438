import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { Routes } from "constants/index";

const SectionOneComponent = ({ smallDevice }) => {
  return (
    <>
      <div className="section-one-wrapper fw-400">
        <div className="container d-flex flex-column flex-md-row align-items-center section-one-wrapper">
          <div
            className={`col-12 col-md-6 px-3 px-sm-5 ${smallDevice && "py-24"}`}
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-offset="0"
          >
            <h1 className={`fw-600 pb-2`}>
              Online Medical Letters <div>Within 3 Hours</div>
            </h1>
            <ul className="list-group list-style-type-none pt-sm-3 pb-4 fw-500">
              <li className="py-2">
                <i className="fa fa-check-circle-o"></i>&nbsp; Get a medical
                letter within 3 hours*
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o"></i>&nbsp; Signed by a
                registered GMC doctors
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o"></i>&nbsp; Confirm validity
                with unique QR code
              </li>
              <li className="py-2">
                <i className="fa fa-check-circle-o"></i>&nbsp; No appointment or
                travel time needed
              </li>
            </ul>
            <Link
              to={Routes.REQUEST_SICKNOTE}
              className="btn btn-blue-rounded px-4 py-2 py-sm-3"
            >
              Request Certificate
            </Link>
          </div>
          <div className="col-12 col-md-6 mt-5 mt-sm-0">
            <img
              src="https://storage.googleapis.com/ondoc-life-assets/banner_img_25.webp"
              alt="Banner image"
              className="col-12 banner-img"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionOneComponent;
