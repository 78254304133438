import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { LocalRoutes } from "routes";

import { LoadingComponent, ScrollIntoViewComponent } from "components";

// Toast for popups
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  return loading ? (
    <div>
      <LoadingComponent />
    </div>
  ) : (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        progressClassName="toast-progress"
        bodyClassName="toast-body"
      />
      <Router>
        <ScrollIntoViewComponent>
          <LocalRoutes />
        </ScrollIntoViewComponent>
      </Router>
    </div>
  );
};

export default App;

// <Route path="/" element={<HomeComponent />} />
