import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  HomeComponent,
  NotFoundComponent,
  RequestSickNoteComponent,
  RequestCovidRecoveryComponent,
  RequestSuccessComponent,
  VerifyCertificateComponent,
  PrivacyPolicyComponent,
  TncComponent,
  AboutUsComponent,
} from "components";

export const LocalRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route path="/pp" element={<PrivacyPolicyComponent />} />
      <Route path="/tnc" element={<TncComponent />} />
      <Route path="/about-us" element={<AboutUsComponent />} />
      <Route path="/request-sicknote" element={<RequestSickNoteComponent />} />
      <Route
        path="/request-covid-recovery"
        element={<RequestCovidRecoveryComponent />}
      />
      <Route path="/request-success" element={<RequestSuccessComponent />} />
      <Route
        path="/verify-certificate"
        element={<VerifyCertificateComponent />}
      />
      <Route path="*" element={<NotFoundComponent />} />
    </Routes>
  );
};

export default Routes;
