import React from "react";
import "./style.css";

const HowItWorksComponent = () => {
  return (
    <>
      <div className="container work-wrapper">
        <div
          className="text-blue h2 text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          So, how does it work?
        </div>
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <img
                src="https://storage.googleapis.com/ondoc-life-assets/step1.webp"
                className="card-img-top"
                alt="Questionnaire"
              />
              <div className="card-body text-center text-blue">
                <div className="h4 text-blue">Submit in 5-minutes</div>
                <p className="text-blue p-2">
                  Answer simple form - about yourself, medical condition and
                  upload ID documents.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <img
                src="https://storage.googleapis.com/ondoc-life-assets/step2.webp"
                className="card-img-top"
                alt="Questionnaire"
              />
              <div className="card-body text-center">
                <div className="h4 text-blue">Quick review</div>
                <p className="text-blue p-2">
                  GMC registered doctor will review the information and respond
                  to you within 3 hours
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 mt-5">
            <div className="card">
              <img
                src="https://storage.googleapis.com/ondoc-life-assets/step3_2.webp"
                className="card-img-top"
                alt="Questionnaire"
              />
              <div className="card-body text-center">
                <div className="h4 text-blue">Get back to rest</div>
                <p className="text-blue p-2">
                  We deliver the digital certificate via email as soon as doctor
                  review completes
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksComponent;
