import React from "react";

import { StaticHeaderComponent, StaticFooterComponent } from "components";

const PrivacyPolicyComponent = () => {
  return (
    <>
      <div>
        <StaticHeaderComponent />
        <div className="container pt-5 px-sm-5 mt-5">
          <h5>Privacy Policy</h5>
          <div className="pt-3">
            <h6>Who we are and what we do</h6>
            <p>
              Ondoc.life respects your privacy and is committed to protecting
              your personal data. This policy (together with our terms of use
              and service conditions applicable to you, as referred to in our
              terms of use) set out the basis on which any personal data we
              collect from you, or that you provide to us, will be processed by
              us when you use the website ondoc.life (the “Site”), the mobile
              application software available at the Site.
            </p>
            <p>
              Ondoc Limited is the data controller and responsible for your
              personal data (referred to as “we”, “us” or “our” in this privacy
              policy). We are registered in England under company number
              14579544 and have our registered office at 1 Craftsman Square,
              Temple Farm Industrial Estate, Southend-On-Sea, England, SS2 5RH.
              Our main trading address is also at that address. We are regulated
              by Companies House.
            </p>
            <p>
              If you have any questions about this privacy policy, including any
              requests to exercise your legal rights, please contact our team at
              contact@ondoc.life
            </p>
            <p>
              Please read the following carefully to understand our views and
              practices regarding your personal data and how we will treat it.
              By visiting and using the Site ondoc.life you are accepting and
              consenting to the practices described in this policy. This policy
              is updated from time to time. The latest version updated on
              19/01/2023 is published on this page of the Site.
            </p>
          </div>
          <div className="pt-3">
            <h6>Information we may collect from you</h6>
            <p>
              Personal data, or personal information, means any information
              about an individual from which that person can be identified. It
              does not include data where the identity has been removed
              (anonymous data). When you visit our Site, ondoc.life or
              correspond with us by phone, e-mail or otherwise you may give us
              information that would be classed as personal data about you and
              others you are acting on behalf of. We may collect, use, store and
              transfer different kinds of personal data about you when you do so
              which we have grouped together follows:
            </p>
            <ul>
              <li>
                Identity Data: Which includes your first name, last name, email,
                date of birth, gender, and ethnicity.
              </li>
              <li>
                Personal Data: Which includes the NHS number, NHS surgery name,
                government-issued ID document, and workplace.
              </li>
              <li>
                Contact Data: Which includes the consultation address you
                provide (which may be your work or home address), billing
                address, email address, telephone numbers, and NHS surgery name.
              </li>
              <li>
                Health Data: Which includes any information about your health
                including your medical history and/or current health status
                including but not limited to data regarding test results,
                diagnoses, and medications.
              </li>
              <li>
                Financial Data: which includes your bank account and payment
                card details.
              </li>
              <li>
                Transaction Data: includes details about payments to and from
                you and other details of products and services you have
                purchased from us.
              </li>
              <li>
                Technical Data: including the type of Device you use, a unique
                device identifier (for example, your Device’s IMEI number, the
                MAC address of the Device’s wireless network interface, or the
                mobile phone number used by the Device), mobile network
                information, your mobile operating system and the type of mobile
                browser you use, and the Internet protocol (IP) address used to
                connect your computer or Device to the Internet, your login
                information, browser type and version, time zone setting,
                browser plug-in types and versions, operating system and
                platform; Profile Data which includes your username and password
                for any accounts set up to access our products and services,
                purchases or orders made by you, feedback responses.
              </li>
              <li>
                Usage Data: which includes information about your use of the
                Site and the Ondoc.life App, including location information, the
                full Uniform Resource Locators (URL) clickstream to, through and
                from our Site and the Ondoc.life App (including date and time);
                services you viewed or searched for; page response times,
                download errors, length of visits to certain pages, page
                interaction information (such as scrolling, clicks, and
                mouse-overs), and methods used to browse away from the page and
                any email address used to contact us.
              </li>
              <li>
                Marketing and Communications Data: which includes your
                preferences in receiving marketing details from us and our third
                parties and your communication preferences.
              </li>
            </ul>
            <p>
              We also collect, use and share aggregated data such as statistical
              or demographic data for any purpose aggregated data may be derived
              from your personal data but is not considered personal data in law
              as this data does not directly or indirectly reveal your identity.
              For example, we may aggregate your usage data to calculate the
              percentage of users accessing a specific website feature. However,
              if we combine or connect aggregated data with your personal data
              so that it can directly or indirectly identify you, we treat the
              combined data as personal data which will be used in accordance
              with this privacy policy.
            </p>
            <p>
              Except for data about your health, we do not collect any Special
              Categories of Personal Data (as defined in GDPR) about you. This
              includes details about your religious or philosophical beliefs,
              sex life, sexual orientation, political opinions, trade union
              membership, information about your health and genetic and
              biometric data. Nor do we collect any information about criminal
              convictions and offences.
            </p>
            <p>
              Where we need to collect personal data by law, or under the terms
              of a contract we have with you and you fail to provide that data
              when requested, we may not be able to perform the contract we have
              or are trying to enter into with you (for example, to provide you
              with goods or services). In this case, we may have to cancel a
              product or service you have with us but we will notify you if this
              is the case at the time.
            </p>
            <p>
              It is important that the personal data we hold about you is
              accurate and current. Please keep us informed if your personal
              data changes during your relationship with us.
            </p>
          </div>
          <div className="pt-3">
            <h6>Keeping your data secure</h6>
            <p>
              We know that data security is important to you and it is therefore
              important to us. We have put in place appropriate security
              measures to prevent your personal data from being accidentally
              lost, used or accessed in an unauthorised way, altered or
              disclosed. In addition, we limit access to your personal data to
              those employees, agents, contractors and other third parties who
              have a business need to know. They will only process your personal
              data on our instructions in accordance with this policy and they
              are subject to a duty of confidentiality.
            </p>
            <p>
              Where we have given you (or where you have chosen) a password
              which enables you to access certain parts of our Site and the
              Ondoc.life App, you are responsible for keeping this password
              confidential. We ask you not to share a password with anyone.
            </p>
            <p>
              We have put in place procedures to deal with any suspected
              personal data breach and will notify you and any applicable
              regulator of a breach where we are legally required to do so.
            </p>
          </div>
          <div className="pt-3">
            <h6>How we collect your data</h6>
            <p>
              We use different methods to collect data from and about you
              including through:
            </p>
            <ul>
              <li>
                Direct interactions: You may give us any of the categories of
                data identified above by filling in forms on our Site or by
                corresponding with us by phone, e-mail or otherwise. This
                includes personal data you provide when you:
                <ul>
                  <li>register to use our Site;</li>
                  <li>make a request for our products or services;</li>
                  <li>request marketing communications to be sent to you;</li>
                  <li>give us some feedback.</li>
                </ul>
              </li>
              <li>
                Automated technologies or interactions: As you interact with our
                Site, we may automatically collect Technical Data about your
                equipment, browsing actions and patterns. We collect this
                personal data by using cookies, and other similar technologies.
                We may also receive Technical Data about you if you visit other
                websites and apps employing our cookies. Please see our cookie
                policy below for further details.
              </li>
              <li>
                Third parties or publicly available sources: We may receive
                personal data about you from various third parties and public
                sources such as analytics providers, advertising networks and/or
                search information providers based inside the EU, including for
                example Google Analytics.
              </li>
              <li>
                Contact, Financial and Transaction Data (if applicable) from
                providers of technical, payment and delivery services such as
                Stripe based inside the EU.
              </li>
              <li>
                Identity and Contact Data from data brokers or aggregators such
                as Firebase, Google Cloud Platform, and 3rd party data
                applications based inside the EU.
              </li>
            </ul>
          </div>
          <div className="pt-3">
            <h6>Why we will use your data</h6>
            <p>
              The lawful bases for processing personal data are set out in
              Article 6 of the General Data Protection Regulation (GDPR). We may
              process your personal data on more than one lawful ground
              depending on the specific purpose for which we are using your
              data. Please contact us if you need details about the specific
              legal ground, we are relying on you to process your personal data
              where more than one ground has been set out below. At least one of
              these must apply whenever we process personal data:
            </p>
            <ul>
              <li>
                Consent: You have given clear consent for us to process your
                personal data for a specific purpose.
              </li>
              <li>
                Contract: The processing is necessary for a contract we have
                with you (for example, to process and deliver goods or services
                to you and to manage our relationship with you), or because you
                have asked us to take specific steps before entering into a
                contract.
              </li>
              <li>
                Legal obligation: The processing is necessary for us to comply
                with a legal or regulatory obligation (not including contractual
                obligations).
              </li>
              <li>
                Vital interests: The processing is necessary to protect
                someone’s life.{" "}
              </li>
              <li>
                Public task: The processing is necessary for us to perform a
                task in the public interest or for our official functions, and
                the task or function has a clear basis in law.
              </li>
              <li>
                Legitimate interests: The processing is necessary for our
                legitimate interests (for example to administer and maintain our
                Site) or the legitimate interests of a third party unless there
                is a good reason to protect your personal data which overrides
                those legitimate interests. We make sure we consider and balance
                any potential impact on you (both positive and negative) and
                your rights before we process your personal data for our
                legitimate interests. We do not use your personal data for
                activities where our interests are overridden by the impact on
                you (unless we have your consent or are otherwise required or
                permitted to by law). You can obtain further information about
                how we assess our legitimate interests against any potential
                impact on you in respect of specific activities by contacting
                us.
              </li>
              <li>
                Healthcare provision: The processing of data concerning your
                health is necessary for us to provide you with a medical
                diagnosis and/or healthcare treatment.
              </li>
            </ul>
            <p>
              <a
                href="https://ico.org.uk/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Click here to find out more
              </a>{" "}
              on the Information Commissioner’s Office website about the types
              of lawful basis that we will rely on to process your personal
              data.
            </p>
            <p>
              Generally, we do not rely on consent as a legal basis for
              processing your personal data other than as described below.
              However, where we do ask for your consent (for example in
              processing data relating to your health) we will do so in order to
              comply with the principle that any processing must be lawful, fair
              and transparent. Please see our terms and conditions for more
              information on this.
            </p>
          </div>
          <div className="pt-3">
            <h6>Disclosing your personal data</h6>
            <p>
              We may have to share your personal data with the parties below in
              order to provide our services to you. We require all third parties
              to respect the security of your personal data and to treat it in
              accordance with the law. We do not allow our third-party service
              providers to use your personal data for their own purposes and
              only permit them to process your personal data for specified
              purposes and in accordance with our instructions. Examples of our
              third parties include:
            </p>
            <p>
              GPs, Doctors, and healthcare professionals who provide services to
              you via ondoc.life. Your regular GP (with your express consent or
              in accordance with a legal or regulation obligation)
            </p>
            <p>
              Sub-contractors for the performance of any contract we enter into
              with them or you. Analytics and search engine providers that
              assist us in the improvement and optimisation of our Site
              ondoc.life We may also disclose your personal data to third
              parties
            </p>
            <p>
              In the event that we sell or buy any business or assets, in which
              case we may disclose your personal data to the prospective seller
              or buyer of such business or assets.
            </p>
            <p>
              If Ondoc Limited or substantially all of its assets are acquired
              by a third party, in which case personal data held by it about its
              customers will be one of the transferred assets.
            </p>
            <p>
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation or in order to enforce
              or apply our terms of use and other terms of service; or to
              protect the rights, property, or safety of Ondoc Limited, Medical
              professionals we make available to you to provide medical
              services, our customers, or others. This may include exchanging
              information with other companies and organisations for the
              purposes of fraud protection and credit risk reduction.
            </p>
            <p>
              The data we collect from you will be transferred to and stored on
              the computer servers of one or more third parties, such servers
              are based within the European Economic Area (“EEA“). All
              information you provide to us is stored on secure servers and
              encrypted. The data will be processed and used by us in accordance
              with this policy.
            </p>
          </div>
          <div className="pt-3">
            <h6>Cookies</h6>
            <p>
              Our Site uses cookies to distinguish you from other users of our
              Site. This helps us to provide you with a good experience when you
              browse our Site and also allows us to improve our site.
            </p>
            <p>
              A cookie is a small file of letters and numbers stored on your
              browser or the hard drive of your computer in order to enhance
              your experience and to provide functionality on the Site.
            </p>
            <p>We use the following cookies:</p>
            <ul>
              <li>
                Strictly necessary cookies: These are cookies that are required
                for the operation of our Site. They include, for example,
                cookies that enable you to log into secure areas of our Site
                such as your personal records and on-line payment services.
              </li>
              <li>
                Analytical/performance cookies: These allow us to recognise and
                count the number of visitors and to see how visitors move around
                our Site when they are using it. This helps us to improve the
                way our Site works, for example, by ensuring that users are
                finding what they are looking for easily.
              </li>
              <li>
                Functionality cookies: These are used to recognize you when you
                return to our Site. This enables us to personalise our content
                for you, greet you by name and remember your preferences (for
                example, your choice of language or region).
              </li>
              <li>
                We may also use Targeting cookies: These cookies record your
                visit to our Site, the pages you have visited and the links you
                have followed. We will use this information to make our Site and
                any content displayed on it more relevant to your interests.
              </li>
            </ul>
            <p>
              You can find more information about the individual cookies we use
              and the purposes for which we use them in the table below:
            </p>
            <p>
              Please note that third parties (including, for example, providers
              of external services like web traffic analysis services) may also
              use cookies, over which we have no control.
            </p>
            <p>
              You may block cookies by activating the relevant settings on your
              browser. However, if you use your browser settings to block all
              cookies (including essential cookies) you may not be able to
              access all or parts of our site.
            </p>
            <p>
              Except for essential cookies, all cookies will expire after no
              more than 30 days.
            </p>
          </div>
          <div className="pt-3">
            <h6>Change of purpose</h6>
            <p>
              We will only use your personal data for the purposes for which we
              collected it unless we reasonably consider that we need to use it
              for another reason and that reason is compatible with the original
              purpose. If you wish to get an explanation as to how the
              processing for the new purpose is compatible with the original
              purpose, please contact us.
            </p>
            <p>
              If we need to use your personal data for an unrelated purpose, we
              will notify you and we will explain the legal basis which allows
              us to do so.
            </p>
            <p>
              Please note that we may process your personal data without your
              knowledge or consent, in compliance with the above rules, where
              this is required or permitted by law.
            </p>
          </div>
          <div className="pt-3">
            <h6>Marketing</h6>
            <p>
              We strive to provide you with choices regarding certain personal
              data uses, particularly around marketing and advertising.
            </p>
            <p>
              We may use your Identity, Contact, Technical, Usage and Profile
              Data to form a view on what we think you may want or need, or what
              may be of interest to you. This is how we decide which products,
              services and offers may be relevant for you (we call this
              marketing).
            </p>
            <p>
              You will receive marketing communications from us if you have
              requested information from us or purchased Services from us and,
              in each case, you have not opted out of receiving that marketing.
            </p>
            <p>
              We will get your express opt-in consent before we share your
              personal data with any other company for marketing purposes. You
              can ask us to stop sending you marketing messages at any time by
              logging into the Site and adjusting your marketing preferences or
              by following the opt-out links on any marketing message sent to
              you or by contacting us at any time.
            </p>
            <p>
              Where you opt out of receiving these marketing messages, this will
              not apply to personal data provided to us as a result of a
              purchase of goods or services from us.
            </p>
            <p>
              Our Site ondoc.life may, from time to time, contain links to and
              from the websites of our partner networks and affiliates. If you
              follow a link to any of these websites, please note that these
              websites have their own privacy policies and that we do not accept
              any responsibility or liability for these policies. Please check
              these policies before you submit any personal data to these
              websites.
            </p>
          </div>
          <div className="pt-3">
            <h6>How long we will keep your data</h6>
            <p>
              We will only retain your personal data for as long as necessary to
              fulfil the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements. We retain personal data in accordance with our
              policy.
            </p>
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature, and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal
              requirements.
            </p>
            <p>
              In some circumstances we may anonymise your personal data (so that
              it can no longer be associated with you) for research or
              statistical purposes in which case we may use this information
              indefinitely without further notice to you.
            </p>
          </div>
          <div className="pt-3">
            <h6>Your legal rights</h6>
            <p>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data. Please click on the link
              to access the Information Commissioner’s Office website to find
              out more about these rights:
            </p>
            <ul>
              <li>Request access to your personal data.</li>
              <li>Request correction of your personal data.</li>
              <li>Request erasure of your personal data.</li>
              <li>Object to processing of your personal data.</li>
              <li>Request restriction of processing your personal data.</li>
              <li>Request transfer of your personal data.</li>
              <li>Right to withdraw consent.</li>
            </ul>
            If you wish to exercise any of the rights set out above, please
            Contact us.
          </div>
          <div className="pt-3">
            <h6>No fee usually required</h6>
            <p>
              You will not have to pay a fee to access your personal data (or to
              exercise any of the other rights). However, we may charge a
              reasonable fee of £20 if your request is clearly unfounded,
              repetitive or excessive. Alternatively, we may refuse to comply
              with your request in these circumstances.
            </p>
          </div>
          <div className="pt-3">
            <h6>What we may need from you</h6>
            <p>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              personal data (or to exercise any of your other rights). This is a
              security measure to ensure that personal data is not disclosed to
              any person who has no right to receive it. We may also contact you
              to ask you for further information in relation to your request to
              speed up our response.
            </p>
          </div>
          <div className="pt-3">
            <h6>Time limit to respond</h6>
            <p>
              We try to respond to all legitimate requests within 30 days.
              Occasionally it may take us longer than 30 days if your request is
              particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated.
            </p>
          </div>
        </div>
        <StaticFooterComponent />
      </div>
    </>
  );
};

export default PrivacyPolicyComponent;
