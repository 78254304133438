import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { StaticHeaderComponent } from "components";

const VerifyCertificateComponent = () => {
  const [loading, setLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [employer, setEmployer] = useState({
    dob: "",
    rowId: "",
  });

  const handleChange = (key, value) => {
    setEmployer({
      ...employer,
      [key]: value,
    });
  };

  const verifyCertificate = async () => {
    if (!employer.dob) {
      toast.error("Date of birth is required");
      return;
    } else if (!employer.rowId) {
      toast.error("Reference Number is required");
      return;
    }
    setLoading(true);
    const dobFormat = moment(employer.dob).format("DD/MM/YYYY");
    await fetch(process.env.REACT_APP_AIRTABLE_URL)
      .then((res) => res.json())
      .then(({ records }) => {
        const rec = records.find(
          (r) => r.id === employer.rowId && r.fields.dob === dobFormat
        );
        if (rec && rec.fields.pdfDocumentLinkShort) {
          setDownloadUrl(rec.fields.pdfDocumentLinkShort);
        } else {
          setDownloadUrl("Not found");
        }
      })
      .catch((e) => {
        setDownloadUrl("Not found");
        console.error("error", e);
      });
    setLoading(false);
  };

  return (
    <div>
      <StaticHeaderComponent />
      <div className="container pt-3 pt-sm-5 mt-sm-5">
        <div className="row pt-5 mt-5">
          <div className="col-12 col-sm-6">
            <h2>Check the validity of the certificate!</h2>
            <p className="pt-3">
              We may not share everything that was discussed due to
              doctor-patient confidentiality but you can verify the certificate
              validity using Reference Number. The Reference Number can be found
              on the certificate.
            </p>
          </div>
          <div className="col-12 col-sm-6 pt-3 pt-sm-0">
            <div className="">
              <label className="text-blue mt-3 fw-500">
                Date of birth <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className="form-control font-small"
                name="form-question"
                placeholder="Date of birth"
                onChange={(e) => handleChange("dob", e.target.value)}
              />
            </div>
            <div className="">
              <label className="text-blue mt-3 fw-500">
                Reference Number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control font-small"
                name="form-question"
                placeholder="Reference Number"
                onChange={(e) => handleChange("rowId", e.target.value)}
              />
              <small className="text-secondary fs-10">
                Get Reference Number From Medical Certificate
              </small>
            </div>
            <div
              className="btn btn-blue-rounded p-2 px-4 mt-4 btn-sm"
              onClick={verifyCertificate}
            >
              Verify Certificate
            </div>{" "}
            <div>
              {downloadUrl && downloadUrl !== "Not found" && (
                <a
                  href={downloadUrl}
                  className="text-blue mt-4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Link
                </a>
              )}
              {downloadUrl && downloadUrl === "Not found" && (
                <div className="fw-500 text-blue mt-4">No document found</div>
              )}
            </div>
            {loading && <div className="spinner-border"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCertificateComponent;
